<template>
  <div class="bidding-page">
    <div class="sub-banner">
      <div class="bgImg">
        <div class="sub-txt">
          <h6>{{getMenu.tips}}</h6>
          <p>{{getMenu.name}}</p>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="search-box">
        <div class="search-item">
          <div class="label">发布时间:</div>
          <span
            :class="[
              'condition-name',
              search_data.beginType === item.type
                ? 'condition-name-active'
                : '',
            ]"
            @click="() => (search_data.beginType = item.type)"
            v-for="item in releaseTime"
            :key="item.name"
            >{{ item.name }}</span
          >
        </div>
        <div class="search-item">
          <div class="label">筛选条件:</div>
          <span
            :class="[
              'condition-name',
              search_data.applyStatus === item.type
                ? 'condition-name-active'
                : '',
            ]"
            @click="() => (search_data.applyStatus = item.type)"
            v-for="item in condition"
            :key="item.name"
            >{{ item.name }}</span
          >
        </div>
        <div class="search-item">
          <div class="label">分类:</div>
          <span
            :class="[
              'condition-name',
              search_data.classifyId === item.type
                ? 'condition-name-active'
                : '',
            ]"
            @click="() => (search_data.classifyId = item.type)"
            v-for="item in classify"
            :key="item.name"
            >{{ item.name }}</span
          >
        </div>
        <div class="search-item">
          <div class="label">关键词:</div>
          <Input
            v-model="name"
            search
            enter-button
            style="width: 300px; margin: 0 10px"
            @on-search="search"
          ></Input>
        </div>
      </div>
      <div class="bidding-list-box">
        <div class="list-header">
          <span>招标名称</span>
          <span>发布日期</span>
        </div>
        <ul>
          <li
            class="list-item"
            v-for="item in biddingList"
            :key="item.id"
            :title="item.name"
            @click="() => goThere('biddingInfo', item.id, item.name)"
          >
            <div class="list-item-title">
              <span class="title">{{ item.name }}</span>
              <span class="date">{{ item.beginTime.split(" ")[0] }}</span>
            </div>
            <div class="list-item-info">
              <div>
                <span>招标方式：</span>
                <span class="result">{{ item.biddingTypeName }}</span>
              </div>
              <div>
                <span>报名截止时间：</span>
                <span class="result">{{ item.applyEndTime }}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="list-footer">
          <Page
            :current="page.pageNo"
            :page-size="page.pageSize"
            :total="page.total"
            :show-elevator="true"
            :show-total="true"
            @on-change="pageChange"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      releaseTime: [
        { name: "全部", type: "" },
        { name: "今天", type: 1 },
        { name: "最近3天", type: 2 },
        { name: "最近7天", type: 3 },
        { name: "最近1个月", type: 4 },
        { name: "最近3个月", type: 5 },
      ],
      condition: [
        { name: "全部", type: "" },
        { name: "报名中", type: 1 },
        { name: "报名结束", type: 2 },
      ],
      classify: [
        { name: "全部", type: "" },
        { name: "工程", type: 1 },
        { name: "货物", type: 2 },
        { name: "服务", type: 3 },
      ],
      search_data: {
        beginType: "",
        applyStatus: "",
        classifyId: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      biddingList: [],
      name: "",
    };
  },
  computed: {
    getMenu() {
      let menu = this.$store.state.permission.menu.filter(
        (item) => item.router == this.$store.state.permission.currentRoute
      )[0];
      return menu;
    },
  },
  methods: {
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },

    search() {
      this.page.pageNo = 1;
      this.getList();
    },

    getList() {
      this.$post(this.$api.BIDDING.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
        name: this.name,
      }).then((res) => {
        this.biddingList = res.list;
        this.page.total = +res.total;
      });
    },
    goThere(router, id, name) {
      this.$router.push({ name: router, query: { bidId: JSON.stringify(id), biddingName: name } });
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    search_data: {
      handler(n) {
        this.page.pageNo = 1;
        this.getList();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.bidding-page {
  width: 100%;
  .sub-banner {
    height: 300px;
    position: relative;

    .bgImg {
      width: 100%;
      height: 100%;
      background: url(/imgs/backgroundImg/01.jpg) no-repeat
        center center;

      .sub-txt {
        text-align: center;
        width: 1200px;
        margin: 0 auto;
        padding-top: 90px;

        h6 {
          font-family: Arial;
          font-size: 40px;
          font-weight: bold;
          color: #003f3c;
          text-transform: uppercase;
        }

        p {
          font-size: 30px;
          color: #003f3c;
          line-height: 40px;
        }
      }
    }
  }
  .cont {
    width: 1200px;
    margin: 0 auto;
  }
  .search-box {
    width: 100%;
    padding: 20px;
    .search-item {
      display: flex;
      align-items: center;
      padding: 5px 0;
      .label {
        font-weight: bold;
        width: 90px;
        text-align: right;
        padding-right: 20px;
        color: #333;
      }
      .condition-name {
        padding: 2px 5px;
        margin: 0 10px;
        cursor: pointer;
        &:hover {
          color: #fff;
          background: #2d8cf0;
        }
      }
      .condition-name-active {
        color: #fff;
        background: #2d8cf0;
      }
    }
  }
  .bidding-list-box {
    width: 100%;
    .list-header {
      background: #f9f9f9;
      font-weight: bold;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px #f0f0f0 solid;
      border-top: 1px #f0f0f0 solid;
      color: #333;
      display: flex;
      justify-content: space-between;
      span {
        padding: 0 20px;
        display: inline-block;
        width: 150px;
        text-align: center;
        &:last-child {
          border-left: 1px #f0f0f0 solid;
        }
      }
    }
    ul {
      .list-item {
        border-bottom: 1px #f2f2f2 solid;
        padding: 20px 10px;
        cursor: pointer;
        &:hover {
          background: #f9f9f9;
        }
        .list-item-title {
          .title {
            font-size: 18px;
            color: #000;
            display: inline-block;
            width: 1030px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .date {
            display: inline-block;
            width: 150px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #999;
          }
        }
        .list-item-info {
          display: flex;
          > div {
            color: #666;
            padding: 10px 60px 0 20px;
            .result {
              color: #999;
            }
          }
        }
      }
    }
    .list-footer {
      text-align: center;
      padding: 30px 0;
    }
  }
}
</style>