var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bidding-page"},[_c('div',{staticClass:"sub-banner"},[_c('div',{staticClass:"bgImg"},[_c('div',{staticClass:"sub-txt"},[_c('h6',[_vm._v(_vm._s(_vm.getMenu.tips))]),_c('p',[_vm._v(_vm._s(_vm.getMenu.name))])])])]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"search-box"},[_c('div',{staticClass:"search-item"},[_c('div',{staticClass:"label"},[_vm._v("发布时间:")]),_vm._l((_vm.releaseTime),function(item){return _c('span',{key:item.name,class:[
            'condition-name',
            _vm.search_data.beginType === item.type
              ? 'condition-name-active'
              : '' ],on:{"click":function () { return (_vm.search_data.beginType = item.type); }}},[_vm._v(_vm._s(item.name))])})],2),_c('div',{staticClass:"search-item"},[_c('div',{staticClass:"label"},[_vm._v("筛选条件:")]),_vm._l((_vm.condition),function(item){return _c('span',{key:item.name,class:[
            'condition-name',
            _vm.search_data.applyStatus === item.type
              ? 'condition-name-active'
              : '' ],on:{"click":function () { return (_vm.search_data.applyStatus = item.type); }}},[_vm._v(_vm._s(item.name))])})],2),_c('div',{staticClass:"search-item"},[_c('div',{staticClass:"label"},[_vm._v("分类:")]),_vm._l((_vm.classify),function(item){return _c('span',{key:item.name,class:[
            'condition-name',
            _vm.search_data.classifyId === item.type
              ? 'condition-name-active'
              : '' ],on:{"click":function () { return (_vm.search_data.classifyId = item.type); }}},[_vm._v(_vm._s(item.name))])})],2),_c('div',{staticClass:"search-item"},[_c('div',{staticClass:"label"},[_vm._v("关键词:")]),_c('Input',{staticStyle:{"width":"300px","margin":"0 10px"},attrs:{"search":"","enter-button":""},on:{"on-search":_vm.search},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]),_c('div',{staticClass:"bidding-list-box"},[_vm._m(0),_c('ul',_vm._l((_vm.biddingList),function(item){return _c('li',{key:item.id,staticClass:"list-item",attrs:{"title":item.name},on:{"click":function () { return _vm.goThere('biddingInfo', item.id, item.name); }}},[_c('div',{staticClass:"list-item-title"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(item.beginTime.split(" ")[0]))])]),_c('div',{staticClass:"list-item-info"},[_c('div',[_c('span',[_vm._v("招标方式：")]),_c('span',{staticClass:"result"},[_vm._v(_vm._s(item.biddingTypeName))])]),_c('div',[_c('span',[_vm._v("报名截止时间：")]),_c('span',{staticClass:"result"},[_vm._v(_vm._s(item.applyEndTime))])])])])}),0),_c('div',{staticClass:"list-footer"},[_c('Page',{attrs:{"current":_vm.page.pageNo,"page-size":_vm.page.pageSize,"total":_vm.page.total,"show-elevator":true,"show-total":true},on:{"on-change":_vm.pageChange}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-header"},[_c('span',[_vm._v("招标名称")]),_c('span',[_vm._v("发布日期")])])}]

export { render, staticRenderFns }